// AffiliatesPage.js
import React from 'react';
import './Dashboard.css';

function AffiliatesPage() {
  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Affiliates</h1>
        <p>Track your affiliate earnings and referrals.</p>
      </header>

      {/* Affiliate Summary */}
      <section className="dashboard-affiliate-summary">
        <div className="affiliate-summary-item">
          <h3>Total Earnings</h3>
          <p>$1,250</p>
        </div>
        <div className="affiliate-summary-item">
          <h3>Total Referrals</h3>
          <p>25</p>
        </div>
      </section>

      {/* Referral Link */}
      <section className="dashboard-referral-link">
        <h2>Your Referral Link</h2>
        <div className="referral-link-box">
          <input type="text" value="https://lovefutures.com/ref/yourcode" readOnly />
          <button onClick={() => navigator.clipboard.writeText('https://lovefutures.com/ref/yourcode')}>
            Copy Link
          </button>
        </div>
      </section>

      {/* Referral List */}
      <section className="dashboard-referral-list">
        <h2>Your Referrals</h2>
        <table className="dashboard-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Joined Date</th>
              <th>Earnings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Trader A</td>
              <td>2023-09-15</td>
              <td>$50</td>
            </tr>
            <tr>
              <td>Trader B</td>
              <td>2023-09-20</td>
              <td>$50</td>
            </tr>
            {/* Add more referrals as needed */}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default AffiliatesPage;
