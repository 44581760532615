// SettingsPage.js
import React, { useState } from 'react';
import './Dashboard.css';

function SettingsPage() {
  const [accountType, setAccountType] = useState('100k Plan');
  const [subscriptionPlan, setSubscriptionPlan] = useState('100k Plan');

  const handleSubscriptionChange = (e) => {
    e.preventDefault();
    // Implement subscription change logic here
    alert(`Subscription changed to ${subscriptionPlan}.`);
  };

  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Settings</h1>
        <p>Manage your account settings.</p>
      </header>

      {/* Account Type */}
      <section className="dashboard-account-type">
        <h2>Account Type</h2>
        <p>Your current account type is: <strong>{accountType}</strong></p>
      </section>

      {/* Change Subscription */}
      <section className="dashboard-change-subscription">
        <h2>Change Subscription</h2>
        <form onSubmit={handleSubscriptionChange} className="subscription-form">
          <div className="form-group">
            <label htmlFor="subscription-plan">Select New Plan</label>
            <select
              id="subscription-plan"
              value={subscriptionPlan}
              onChange={(e) => setSubscriptionPlan(e.target.value)}
            >
              <option value="50k Plan">50k Plan - $99/month</option>
              <option value="100k Plan">100k Plan - $199/month</option>
              <option value="150k Plan">150k Plan - $299/month</option>
            </select>
          </div>
          <button type="submit">Change Subscription</button>
        </form>
      </section>
    </div>
  );
}

export default SettingsPage;
