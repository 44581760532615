// Dashboard.js
import React, { useState } from 'react';
import './Dashboard.css';
import {
  FaChartLine,
  FaExchangeAlt,
  FaUsers,
  FaTrophy,
  FaDollarSign,
  FaCog,
  FaSignOutAlt,
  FaBars,
  FaTimes,
} from 'react-icons/fa';
import { Routes, Route, NavLink } from 'react-router-dom';

import OverviewPage from './OverviewPage';
import TradingPage from './TradingPage';
import AffiliatesPage from './AffiliatesPage';
import CompetitionsPage from './CompetitionsPage';
import PayoutsPage from './PayoutsPage';
import SettingsPage from './SettingsPage';

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    // Implement logout logic here
    // e.g., clear user session, redirect to login page
  };

  return (
    <div className="dashboard-component">
      {/* Sidebar */}
      <aside className={`dashboard-sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="dashboard-logo">
          <h2>LoveFutures</h2>
          <button className="dashboard-close-button" onClick={toggleSidebar}>
            <FaTimes />
          </button>
        </div>
        <nav className="dashboard-sidebar-nav">
          <ul>
            <li>
              <NavLink to="/dashboard/overview" activeClassName="active">
                <FaChartLine /> Overview
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/trading" activeClassName="active">
                <FaExchangeAlt /> Trading
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/affiliates" activeClassName="active">
                <FaUsers /> Affiliates
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/competitions" activeClassName="active">
                <FaTrophy /> Competitions
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/payouts" activeClassName="active">
                <FaDollarSign /> Payouts
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/settings" activeClassName="active">
                <FaCog /> Settings
              </NavLink>
            </li>
          </ul>
        </nav>
        <button className="dashboard-logout-button">
          <FaSignOutAlt /> Log Out
        </button>
      </aside>

      {/* Overlay for Mobile Sidebar */}
      {sidebarOpen && <div className="dashboard-overlay" onClick={toggleSidebar}></div>}

      {/* Main Content */}
      <main className="dashboard-main-content">
        {/* Mobile Toggle Button */}
        <button className="dashboard-toggle-button" onClick={toggleSidebar}>
          <FaBars />
        </button>

        {/* Main Content Routes */}
        <Routes>
          <Route path="/" element={<OverviewPage />} />
          <Route path="/overview" element={<OverviewPage />} />
          <Route path="/trading" element={<TradingPage />} />
          <Route path="/affiliates" element={<AffiliatesPage />} />
          <Route path="/competitions" element={<CompetitionsPage />} />
          <Route path="/payouts" element={<PayoutsPage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Routes>
      </main>
    </div>
  );
}

export default Dashboard;
