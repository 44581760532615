import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './Register.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51Q6M1sJ2hpmnqmqYqSaGNNH65jNq0rf76i8iWquO4ajqWKojmhL6vqfetU09rImDqOhqaNIiaBRbVWaHYSRURlBF00tppJo6f6'); // Replace with your Stripe publishable key

function Register() {
  const { plan } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const planDetails = {
    '50': { name: '50k Plan', price: '$99/month' },
    '100': { name: '100k Plan', price: '$199/month' },
    '150': { name: '150k Plan', price: '$299/month' },
  };

  const selectedPlan = planDetails[plan];

  const handlePlanSelect = (planId) => {
    navigate(`/register/${planId}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if Stripe has loaded
    if (!stripe || !elements) {
      setError('Stripe is not loaded.');
      return;
    }

    try {
      // Step 1: Register the user
      const registrationResponse = await fetch('https://api.sdvxindex.com/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!registrationResponse.ok) {
        const errorData = await registrationResponse.text();
        setError(errorData || 'Registration failed');
        return;
      }

      // Step 2: Create a payment method with Stripe
      const cardElement = elements.getElement(CardElement);
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { name: nameOnCard, email },
      });

      if (stripeError) {
        setError(stripeError.message);
        return;
      }

      // Step 3: Send payment details to the server
      const paymentResponse = await fetch('https://api.sdvxindex.com/api/payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          email: email,
          amount: parseInt(selectedPlan.price.replace(/[^0-9]/g, '')) * 100, // Convert price to cents
        }),
      });

      if (!paymentResponse.ok) {
        const paymentErrorData = await paymentResponse.text();
        setError(paymentErrorData || 'Payment failed');
        return;
      }

      // If payment is successful, redirect to the dashboard
      navigate('/dashboard');
    } catch (err) {
      console.error('Error during registration or payment:', err);
      setError('An unexpected error occurred');
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="register-page">
        <div className="register-container">
          <Link to="/" className="register-logo">
            LoveFutures
          </Link>

          {!plan ? (
            <div className="register-choose-plan">
              <h2>Choose a plan to get started</h2>
              <div className="register-plans">
                {Object.keys(planDetails).map((planId) => (
                  <div
                    key={planId}
                    className="register-plan"
                    onClick={() => handlePlanSelect(planId)}
                  >
                    <p>{planDetails[planId].name}</p>
                    <h3>{planDetails[planId].price}</h3>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="register-form-wrapper">
              <h2>You're almost there! Complete your registration</h2>

              {/* Selected Plan Details */}
              <div className="register-selected-plan">
                <div className="register-plan">
                  <p>{selectedPlan?.name}</p>
                  <h3>{selectedPlan?.price}</h3>
                </div>
                <button
                  onClick={() => navigate('/register')}
                  className="change-plan-button"
                >
                  Change Plan
                </button>
              </div>

              {/* Account Creation Form */}
              <form className="register-form" onSubmit={handleSubmit}>
                <h3>Create Your Account</h3>
                <div className="register-form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="register-form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <h3>Payment Details</h3>
                <div className="register-form-group">
                  <label htmlFor="card-name">Name on Card</label>
                  <input
                    type="text"
                    id="card-name"
                    placeholder="Enter name on card"
                    required
                    value={nameOnCard}
                    onChange={(e) => setNameOnCard(e.target.value)}
                  />
                </div>
                <div className="register-form-group">
                  <label>Card Details</label>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: 'rgb(255, 250, 235)',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#fa755a',
                          iconColor: '#fa755a',
                        },
                      },
                    }}
                  />
                </div>

                {error && <p className="register-error">{error}</p>}

                <button className="register-submit-button" type="submit">
                  Submit Secure Payment
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </Elements>
  );
}

export default Register;
