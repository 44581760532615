// PayoutsPage.js
import React, { useState } from 'react';
import './Dashboard.css';

function PayoutsPage() {
  const [payoutAmount, setPayoutAmount] = useState('');

  const handlePayoutRequest = (e) => {
    e.preventDefault();
    // Implement payout request logic here
    alert(`Payout request of $${payoutAmount} submitted.`);
    setPayoutAmount('');
  };

  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Payouts</h1>
        <p>View and request your payouts.</p>
      </header>

      {/* Payout Balance */}
      <section className="dashboard-payout-balance">
        <h2>Available Balance</h2>
        <p>$5,000</p>
      </section>

      {/* Request Payout */}
      <section className="dashboard-request-payout">
        <h2>Request Payout</h2>
        <form onSubmit={handlePayoutRequest} className="payout-form">
          <div className="form-group">
            <label htmlFor="payout-amount">Amount</label>
            <input
              type="number"
              id="payout-amount"
              value={payoutAmount}
              onChange={(e) => setPayoutAmount(e.target.value)}
              placeholder="Enter amount"
              required
            />
          </div>
          <button type="submit">Submit Request</button>
        </form>
      </section>

      {/* Payout History */}
      <section className="dashboard-payout-history">
        <h2>Payout History</h2>
        <table className="dashboard-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2023-09-30</td>
              <td>$2,000</td>
              <td>Completed</td>
            </tr>
            <tr>
              <td>2023-08-31</td>
              <td>$1,500</td>
              <td>Completed</td>
            </tr>
            {/* Add more payout history entries as needed */}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default PayoutsPage;
