// OverviewPage.js
import React from 'react';
import './Dashboard.css';

function OverviewPage() {
  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Overview</h1>
        <p>Here's a quick overview of your performance today.</p>
      </header>

      <section className="dashboard-financial-overview">
        <div className="dashboard-overview-item">
          <h3>Today's Profit</h3>
          <p>$18.6K</p>
        </div>
        <div className="dashboard-overview-item">
          <h3>Drawdown</h3>
          <p>55%</p>
        </div>
        <div className="dashboard-overview-item">
          <h3>Trading Days</h3>
          <p>10</p>
        </div>
        <div className="dashboard-overview-item">
          <h3>Current Balance</h3>
          <p>$15,000</p>
        </div>
      </section>

      <section className="dashboard-statistics-overview">
        <h2>Statistics Overview</h2>
        <div className="dashboard-statistics-placeholder">[Graph Placeholder]</div>
      </section>

      <section className="dashboard-summary-overview">
        <h2>Summary</h2>
        <div className="dashboard-summary-placeholder">[Summary Table Placeholder]</div>
      </section>
    </div>
  );
}

export default OverviewPage;
