// Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.sdvxindex.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Logged in:', data);
        navigate('/dashboard/overview');
      } else {
        const errorData = await response.text();
        console.error('Error response text:', errorData);
        setError(errorData || 'Login failed');
      }
    } catch (err) {
      console.error('Error during login:', err);
      setError('An unexpected error occurred');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <Link to="/" className="login-logo">
          LoveFutures
        </Link>
        <div className="login-form-wrapper">
          <h2>Log In</h2>
          <form onSubmit={handleSubmit}>
            <div className="login-form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login-form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login-form-options">
              <div>
                <input type="checkbox" id="remember" />
                <label htmlFor="remember">Remember me</label>
              </div>
            </div>
            <button type="submit" className="login-button">
              Log In
            </button>
          </form>
          {error && <p className="login-error">{error}</p>}
          <div className="login-links">
            <Link to="/forgot-password">Forgot password?</Link>
            <Link to="/cant-access-account">Can't Access Your Account?</Link>
          </div>
          <div className="login-register-link">
            <p>
              Not a member yet?{' '}
              <Link to="/register">Register here</Link> and get started now!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
