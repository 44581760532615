// CompetitionsPage.js
import React from 'react';
import './Dashboard.css';

function CompetitionsPage() {
  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Competitions</h1>
        <p>Participate in trading competitions and view leaderboards.</p>
      </header>

      {/* Current Competitions */}
      <section className="dashboard-current-competitions">
        <h2>Current Competitions</h2>
        <div className="competition-item">
          <h3>Monthly Trading Challenge</h3>
          <p>Start Date: October 1, 2023</p>
          <p>End Date: October 31, 2023</p>
          <p>Prize Pool: $10,000</p>
          <button>Join Now</button>
        </div>
        {/* Add more competitions as needed */}
      </section>

      {/* Leaderboard */}
      <section className="dashboard-leaderboard">
        <h2>Leaderboard</h2>
        <table className="dashboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Trader</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Trader X</td>
              <td>$25,000</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Trader Y</td>
              <td>$20,000</td>
            </tr>
            <tr>
              <td>3</td>
              <td>You</td>
              <td>$18,600</td>
            </tr>
            {/* Add more leaderboard entries as needed */}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default CompetitionsPage;
