import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaUser, FaHeadset } from 'react-icons/fa';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';  // Import Elements from Stripe
import './App.css';
import Dashboard from '../Dashboard/Dashboard';
import FAQ from '../FAQ/FAQ';
import Login from '../Login/Login';
import Register from '../Register/Register';
const stripePromise = loadStripe('pk_test_51Q6M1sJ2hpmnqmqYqSaGNNH65jNq0rf76i8iWquO4ajqWKojmhL6vqfetU09rImDqOhqaNIiaBRbVWaHYSRURlBF00tppJo6f6');

function App() {
  const scrollToPlans = () => {
    const plansSection = document.getElementById('plans-section');
    if (plansSection) {
      plansSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {/* Header */}
                  <header className="app-header">
                    <nav className="app-nav">
                      <div className="app-logo">
                        <a href="/">LoveFutures</a>
                      </div>
                      <input type="checkbox" id="app-menu-toggle" className="app-menu-toggle" />
                      <label htmlFor="app-menu-toggle" className="app-menu-icon">
                        <span></span>
                      </label>
                      <ul className="app-menu">
                        <li><a href="/">Home</a></li>
                        <li><a href="#plans-section">Products</a></li>
                        <li><a href="#contact">Contact</a></li>
                        <li><a href="#dashboard">Dashboard</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li className="app-menu-login">
                          <button
                            className="app-login-button"
                            onClick={() => (window.location.href = '/login')}
                          >
                            Log In
                          </button>
                        </li>
                      </ul>
                      <div className="app-header-right">
                        <button
                          className="app-login-button"
                          onClick={() => (window.location.href = '/login')}
                        >
                          Log In
                        </button>
                      </div>
                    </nav>
                  </header>

                  {/* Deal Section */}
                  <section id="deal">
                    <div className="deal-content">
                      <div className="deal-left">
                        <h2>Join Our Waitlist!</h2>
                        <p>
                          Be the first to get access to our exclusive trading platform. Join the waitlist today and secure
                          your spot for early access to premium trading tools and resources when we launch.
                        </p>
                        <ul>
                          <li>Early access to our cutting-edge trading platform with real-time data.</li>
                          <li>Priority support from our experienced team of traders.</li>
                          <li>Exclusive offers and updates for early registrants.</li>
                        </ul>
                        <p className="price">Secure your spot today!</p>
                        <button className="claim-button" onClick={scrollToPlans}>
                          Join Waitlist
                        </button>
                      </div>
                      <div className="deal-right">
                        <video className="deal-video" loop autoPlay muted>
                          <source src={require('../../images/intro_vid.mov')} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </section>

                  {/* Reviews Section */}
                  <section id="reviews">
                    <h2>Our Reviews</h2>
                    <div className="reviews-container">
                      <div className="review">
                        <p>⭐⭐⭐⭐⭐</p>
                        <p>
                          "LoveFutures has completely transformed my trading experience. The tools and resources
                          available are top-notch, and the support team is always there when I need them."
                        </p>
                        <p>- Karina C.</p>
                      </div>
                      <div className="review">
                        <p>⭐⭐⭐⭐⭐</p>
                        <p>
                          "Thanks to LoveFutures, I’ve been able to achieve consistent profits and grow my
                          portfolio beyond my expectations. Highly recommend for serious traders."
                        </p>
                        <p>- Isabelle D.</p>
                      </div>
                      <div className="review">
                        <p>⭐⭐⭐⭐⭐</p>
                        <p>
                          "The first-time customer deal was the perfect opportunity for me to test the waters.
                          Now, I’m a long-term customer reaping the benefits."
                        </p>
                        <p>- Max T.</p>
                      </div>
                    </div>
                  </section>

                  {/* Plans Section */}
                  <section id="plans-section">
                    <h2>Get started with a plan today</h2>
                    <p>
                      Choose from our range of plans designed to suit traders at every level. Each plan comes
                      with a 30-day money-back guarantee, ensuring that you can trade with confidence.
                    </p>
                    <div className="plans-container">
                      <div className="plan">
                        <h3>50k</h3>
                        <p className="price">$149/month</p>
                        
                        <div className="detail">
                          <span className="detail-label">Intraday Trailing Max Drawdown:</span>
                          <span className="detail-value">$2500</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Maximum Contracts:</span>
                          <span className="detail-value">5</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Minimum Trading Days:</span>
                          <span className="detail-value">10</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Scaling:</span>
                          <span className="detail-value">Yes</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Max Accounts:</span>
                          <span className="detail-value">10</span>
                        </div>
                        
                        <button
                          className="select-button"
                          onClick={() => (window.location.href = '/register/50')}
                        >
                          Choose Plan
                        </button>
                      </div>

                      <div className="plan most-popular">
                        <h3>100k</h3>
                        <p className="price">$199/month</p>
                        
                        <div className="detail">
                          <span className="detail-label">Intraday Trailing Max Drawdown:</span>
                          <span className="detail-value">$5000</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Maximum Contracts:</span>
                          <span className="detail-value">10</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Minimum Trading Days:</span>
                          <span className="detail-value">10</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Scaling:</span>
                          <span className="detail-value">Yes</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Max Accounts:</span>
                          <span className="detail-value">10</span>
                        </div>
                        
                        <button
                          className="select-button"
                          onClick={() => (window.location.href = '/register/100')}
                        >
                          Choose Plan
                        </button>
                      </div>
                      <div className="plan">
                        <h3>150k</h3>
                        <p className="price">$299/month</p>
                        
                        <div className="detail">
                          <span className="detail-label">Intraday Trailing Max Drawdown:</span>
                          <span className="detail-value">$7500</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Maximum Contracts:</span>
                          <span className="detail-value">15</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Minimum Trading Days:</span>
                          <span className="detail-value">10</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Scaling:</span>
                          <span className="detail-value">Yes</span>
                        </div>
                        <div className="detail">
                          <span className="detail-label">Max Accounts:</span>
                          <span className="detail-value">10</span>
                        </div>
                        
                        <button
                          className="select-button"
                          onClick={() => (window.location.href = '/register/150')}
                        >
                          Choose Plan
                        </button>
                      </div>
                    </div>
                  </section>

                  {/* Dashboard Section */}
                  <section id="dashboard">
                    <div className="dashboard-container">
                      <div className="dashboard-left">
                        <img className="dashboard-placeholder" src={require('../../images/dashboard.png')} alt="Dashboard Placeholder" />
                      </div>
                      <div className="dashboard-right">
                        <h3>EASY TO USE DASHBOARD</h3>
                        <h2>See everything. Control everything.</h2>
                        <p>
                          Monitor all aspects of your trading performance with our intuitive dashboard. Easily
                          track your portfolio, manage your positions, and stay informed with real-time updates.
                        </p>
                        <p>
                          Designed for both novice and professional traders, our dashboard provides the tools
                          you need to succeed in today's fast-paced markets.
                        </p>
                      </div>
                    </div>
                  </section>

                  {/* Contact Section */}
                  <section id="contact">
                    <div className="contact-container">
                      <div className="contact-left">
                        <h2>24/7 Customer Support</h2>
                        <ul>
                          <li>
                            Reach out to us any time of day for expert support via live chat or email.
                          </li>
                          <li>
                            Our support team is fluent in multiple languages to assist traders from all around
                            the globe.
                          </li>
                          <li>
                            Get quick responses with an average response time of under 5 minutes.
                          </li>
                        </ul>
                      </div>
                      <div className="contact-right">
                        <div className="chat-box">
                          <div className="chat-header">
                            <span>Active</span>
                            <span className="close-btn">×</span>
                          </div>
                          <div className="chat-message">
                            <div className="user-message">
                              <FaUser size={24} className="user-icon" />
                              <p>How can I increase my position size?</p>
                            </div>
                            <div className="support-message">
                              <FaHeadset size={24} className="support-icon" />
                              <p>
                                You can upgrade your plan or reach the next profit target to unlock higher
                                limits.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* Footer */}
                  <footer className="footer">
                    <div className="footer-content">
                      <div className="footer-section">
                        <h3>RESOURCES</h3>
                        <ul>
                          <li><a href="#">Trading Guide</a></li>
                          <li><a href="#">Market Analysis</a></li>
                          <li><a href="#">Help Center</a></li>
                          <li><a href="#">Contact Support</a></li>
                        </ul>
                      </div>
                      <div className="footer-section">
                        <h3>ABOUT</h3>
                        <ul>
                          <li><a href="#">About Us</a></li>
                          <li><a href="#">Our Team</a></li>
                          <li><a href="#">FAQs</a></li>
                          <li><a href="#">Partners</a></li>
                        </ul>
                      </div>
                      <div className="footer-section">
                        <h3>NEWS</h3>
                        <ul>
                          <li><a href="#">Status Updates</a></li>
                          <li><a href="#">Market Trends</a></li>
                          <li><a href="#">Newsletter</a></li>
                        </ul>
                      </div>
                      <div className="footer-section">
                        <h3>EXTRAS</h3>
                        <ul>
                          <li><a href="#">Careers</a></li>
                          <li><a href="#">Community</a></li>
                          <li><a href="#">Affiliates</a></li>
                        </ul>
                      </div>
                      <div className="footer-section newsletter">
                        <h3>Subscribe to our newsletter!</h3>
                        <form>
                          <input type="email" placeholder="Enter your email" />
                          <div className="checkbox">
                            <input type="checkbox" id="terms" />
                            <label htmlFor="terms">
                              I have read and agree to the <a href="#">Terms of Use</a> and{' '}
                              <a href="#">Privacy Policy</a>.
                            </label>
                          </div>
                          <button type="submit">SUBMIT</button>
                        </form>
                      </div>
                    </div>
                    <div className="footer-bottom">
                      <p>&copy; 2024 Love Futures. All rights reserved.</p>
                      <p>
                        <a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a> |{' '}
                        <a href="#">Risk Disclosure</a>
                      </p>
                      <p>
                        Trader Evaluation Disclaimer: The results and performance mentioned on this website are
                        based on simulated or hypothetical trading results that have certain limitations. Unlike
                        the results shown in an actual performance record, these results do not represent actual
                        trading and may not be comparable to those achieved in real trading scenarios.
                      </p>
                    </div>
                  </footer>
                </>
              }
            />

            {/* Other Routes */}
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/:plan" element={<Register />} />
          </Routes>
        </div>
      </Router>
    </Elements>
  );
}

export default App;
