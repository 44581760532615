// TradingPage.js
import React from 'react';
import './Dashboard.css';

function TradingPage() {
  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Trading</h1>
        <p>Manage your trades and view market data.</p>
      </header>

      {/* Open Positions */}
      <section className="dashboard-open-positions">
        <h2>Open Positions</h2>
        <table className="dashboard-table">
          <thead>
            <tr>
              <th>Instrument</th>
              <th>Quantity</th>
              <th>Entry Price</th>
              <th>Current Price</th>
              <th>P&L</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ES</td>
              <td>2</td>
              <td>$4,500.00</td>
              <td>$4,520.00</td>
              <td className="positive">+$400.00</td>
            </tr>
            <tr>
              <td>NQ</td>
              <td>1</td>
              <td>$15,000.00</td>
              <td>$14,950.00</td>
              <td className="negative">-$500.00</td>
            </tr>
            {/* Add more positions as needed */}
          </tbody>
        </table>
      </section>

      {/* Market Data */}
      <section className="dashboard-market-data">
        <h2>Market Data</h2>
        <div className="dashboard-market-data-grid">
          <div className="market-data-item">
            <h3>ES</h3>
            <p>Price: $4,520.00</p>
            <p className="positive">+0.5%</p>
          </div>
          <div className="market-data-item">
            <h3>NQ</h3>
            <p>Price: $14,950.00</p>
            <p className="negative">-0.3%</p>
          </div>
          <div className="market-data-item">
            <h3>CL</h3>
            <p>Price: $70.00</p>
            <p className="positive">+1.2%</p>
          </div>
          {/* Add more market data items as needed */}
        </div>
      </section>
    </div>
  );
}

export default TradingPage;
